import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import 'core-js';
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import ApolloProvider from './providers/ApolloProvider'
import { icons } from './assets/icons'

React.icons = icons
ReactDOM.render( ApolloProvider, document.getElementById('root'));
serviceWorker.register();
