const initialState = {
    sidebarShow: 'responsive'
  }
export default function headerToggleNav(state = initialState, {type, ...rest}) { 
  switch (type) {
    case 'SET_TOGGLE_NAV':
      return {...state, ...rest }
    case 'up':
      return {...state, ...rest }
    default:
      return state;
  }

}