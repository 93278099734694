export const TOKEN_KEY = "@airbnb-Token";
export const TOKEN_USER = "@airbnb-User";

export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;

export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const getUser = () => localStorage.getItem(TOKEN_USER);

export const login = (token, user) => {
  localStorage.setItem(TOKEN_KEY, `Bearer ${token}`);
  localStorage.setItem(TOKEN_USER, JSON.stringify(user));
};

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(TOKEN_USER);
};