import React from 'react';
import App from '../App';
import {ApolloClient} from '@apollo/client';
import { ApolloProvider, InMemoryCache } from '@apollo/react-hooks';

import { setContext } from '@apollo/client/link/context';
import { Provider } from 'react-redux'
import store from '../store'
import { onError } from 'apollo-link-error';
import { getToken } from '../views/pages/login/services/auth';
import { createUploadLink } from 'apollo-upload-client'
import configAPI from '../assets/config';



const httpLink =  createUploadLink({uri:`${ configAPI.API_URL }/graphql`});

const errorLink = onError(({graphQLErrors, networkError}) => {
    if (networkError) {
        try {
            JSON.parse(networkError.bodyText);
        } catch (e) {
            networkError.message = networkError.bodyText;
        }
    }
});

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = getToken();
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        "content-type" : "application/json",
        authorization: token ? token : "",
      }
    }
  });

  
  const client = new ApolloClient({
    link: authLink.concat(httpLink, errorLink),
    cache: new InMemoryCache(),
  });

export default (
    
    <ApolloProvider client={client}>
        <Provider store={store}>
            <App />
        </Provider>
    </ApolloProvider>
    
)